import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-webgl';
import { Box, Container, Link, Divider, Button, Typography, Paper, Grid, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
  TextField, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import Webcam from 'react-webcam';
import { useDispatch } from 'react-redux';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import Metrics from './Metrics';
import ThumbnailSelector from '../Video/ThumbnailSelector';
import { Analyzer } from './analyze';
import { drawKeypoints, draw2DSkeleton, draw3DSkeleton, drawFeedback, drawText } from "./utilities";
import { compare } from './math';
import Transition from '../Transition/Transition';
import { createLog } from '../../actions/log';
import { createVideo } from '../../actions/video';
import { incrementStats } from '../../actions/stats';


function Runnerhelper({...rest}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('profile'));

  const [debug, setDebug] = useState('');

  const [count, setCount] = useState(0);
  const [phase, setPhase] = useState(0);

  // countVar and phaseVar are used to keep track of the current count and phase between renders
  let countVar = useRef(0);
  let phaseVar = useRef(0);

  // inFrame is used to keep track of whether the user is in frame or not
  const [inFrame, setInFrame] = useState(true);

  // isLoading is used to keep track of whether the model is loading or not
  const [isLoading, setIsLoading] = useState(true);

  // metrics
  const [controlScore, setControlScore] = useState(0.1);

  // confirmation dialog for video upload
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = async () => {
    // stop recording and create video URL
    setDebug('Opening confirmation dialog');
    await stopRecording();
    setOpenConfirmation(true);
    setDebug('Recording stopped, dialog opened');
  };
  const handleCloseConfirmation = () => {
    if (workoutCount) {
      // discard the video and move to the next exercise
      handleDiscard();
    } else {
      // start the recording again
      startRecording();
      setOpenConfirmation(false);
    }
  };

  // text field states for video's title, description, and thumbnail
  const [vidTitle, setVidTitle] = useState('');
  const [vidDesc, setVidDesc] = useState('');
  const [vidVisibility, setVidVisibility] = useState('private');
  const [vidThumbUrl, setVidThumbUrl] = useState(null);

  // webcam and canvas refs
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  
  // hooks for recording a video of the workout
  const offscreenCanvasRef = useRef(null); // offscreen canvas storing combined video and main canvas overlay
  const mediaRecorderRef = useRef(null);

  let capturing = useRef(false);

  const [recordedChunks, setRecordedChunks] = useState([]);
  const recordedChunksRef = useRef([]);
  const [videoURL, setVideoURL] = useState('');
  const FPS = 10;
  const mimeType = MediaRecorder.isTypeSupported('video/webm;codecs=vp8')
      ? 'video/webm;codecs=vp8'
      : 'video/mp4';
  const mimeToExtensionMap = {
    'video/webm;codecs=vp8': 'webm',
    'video/mp4': 'mp4',
  };

  // recording the canvas
  const startRecording = useCallback(() => {
    capturing.current = true;

    const offscreenCanvas = offscreenCanvasRef.current;
    const stream = offscreenCanvas.captureStream(FPS);

    mediaRecorderRef.current = new MediaRecorder(stream, { mimeType, videoBitsPerSecond: 1000000 });

    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.addEventListener('error', handleError);

    mediaRecorderRef.current.start();
    setDebug('Recording started, mimeType:' + mimeType);
  }, []);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => {
        const updatedChunks = prev.concat(data);
        recordedChunksRef.current = updatedChunks;
        return updatedChunks;
      });
    }
  }, []);

  const handleError = useCallback((event) => {
    console.error('Video recording error:', event.error);
    setDebug('Video recording error' + event.error);
  }, []);

  const waitForChunksToUpdate = () => {
    return new Promise((resolve) => {
      const checkChunks = () => {
        if (recordedChunksRef.current.length > 0) {
          resolve();
        } else {
          setTimeout(checkChunks, 100);
        }
      };
      checkChunks();
    });
  };

  const stopRecording = useCallback(() => {
    return new Promise((resolve, reject) => {
      mediaRecorderRef.current.addEventListener('stop', async () => {
        capturing.current = false;

        setDebug('Recording stopped');
  
        // Wait for the recordedChunksRef to be updated
        try {
          await waitForChunksToUpdate();
        } catch (error) {
          console.error(error);
          reject(error);
          setDebug('Error waiting for chunks to update');
          return;
        }
  
        // Create a URL for the video data
        if (recordedChunksRef.current.length > 0) {
          const blob = new Blob(recordedChunksRef.current, {
            type: mimeType,
          });
          const url = URL.createObjectURL(blob);
          setVideoURL(url);
        } else {
          setDebug('No recorded chunks available');
          console.error('No recorded chunks available');
        }
        resolve();
      }, { once: true });
      mediaRecorderRef.current.stop();
      setDebug('Stopping recording');
    });
  }, []);

  // Function to convert data URL to file
  const dataURLToFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleUpload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (recordedChunksRef.current.length > 0) {
          const blob = new Blob(recordedChunksRef.current, { type: mimeType });
          const fileExtension = mimeToExtensionMap[mimeType] || 'webm';
          const videoFile = new File([blob], `video_${Date.now()}.${fileExtension}`, { type: mimeType });

          const thumbnailFile = vidThumbUrl ? dataURLToFile(vidThumbUrl, 'video_thumbnail.jpg') : null;

          const formData = new FormData();
          formData.append('file', videoFile);
          formData.append('thumbnail', thumbnailFile);
          formData.append('title', vidTitle);
          formData.append('description', vidDesc);
          formData.append('creator', user?.result?._id);
          formData.append('visibility', vidVisibility);

          const response = await dispatch(createVideo(formData)); // the video ID
          console.log(`Video upload successful: ${response}`);
          resolve(response);
        } else {
          reject(new Error('No recorded chunks available'));
        }
      } catch (error) {
        console.error('Error uploading video:', error);
        reject(error);
      }
    });
  };

  // Function to combine webcam and canvas content
  const combineStreams = useCallback(() => {
    const offscreenCanvas = offscreenCanvasRef.current;
    const offscreenCtx = offscreenCanvas.getContext('2d', { willReadFrequently: true });
    const webcamVideo = webcamRef.current.video;
    const canvas = canvasRef.current;

    if (webcamVideo.readyState === 4) {
      offscreenCanvas.width = webcamVideo.videoWidth;
      offscreenCanvas.height = webcamVideo.videoHeight;
      offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height); // Clear previous frames
      offscreenCtx.drawImage(webcamVideo, 0, 0, offscreenCanvas.width, offscreenCanvas.height);
      offscreenCtx.drawImage(canvas, 0, 0, offscreenCanvas.width, offscreenCanvas.height);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (capturing.current) stopRecording();
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [capturing.current, stopRecording]);

  // get props
  const exercise = rest.input;
  const workoutCount = rest.workoutCount;
  const workoutTitle = rest.workoutTitle;
  const title = exercise?.title
  const phaseNames = exercise?.phaseNames;
  const numPhases = phaseNames?.length;
  const isStatic = numPhases === 1;
  let staticStarted = 0;
  let startTime = null;
  let staticFailed = 0;

  const phaseAngles = exercise?.angles;
  // array of the seconds someone has to hold each phase for it to count
  const durations = exercise?.durations;
  let currentDuration = -1;
  let durStart = null;

  // significance threshold for a dynamic angle
  const SIG = 25;

  // find dynamic angles
  let dynamicAngles = [];

  if (phaseAngles) {
    // array of angle names
    const ANGLES = phaseAngles ? Object.keys(phaseAngles[0].angles3D) : null;

    
    if (!isStatic) {
      for (let phaseNum = 1; phaseNum < phaseAngles.length; phaseNum++) {
        const angles = phaseAngles[phaseNum].angles3D;
        for (let i = 0; i < ANGLES.length; i++) {
          if (!compare(angles[ANGLES[i]], phaseAngles[0].angles3D[ANGLES[i]], SIG)) {
            dynamicAngles.push(ANGLES[i]);
          }
        }
      }
    }
  }

  // run the analyzer when the exercise data is loaded
  useEffect(() => {
    const init = async () => {
      await tf.ready();
    };

    if (exercise) {
      init();
      const analyzer = new Analyzer(dynamicAngles);

      const detectInterval = setInterval(() => {
        detect(analyzer);
      }, 100);

      const combineInterval = setInterval(() => {
        combineStreams();
      }, 1000 / FPS);

      return () => {
        clearInterval(detectInterval);
        clearInterval(combineInterval);
      };
    }
  }, [exercise]);

  // advances the phase, and changes count or exercise if necessary
  const advancePhase = async () => {
    if (!capturing.current && phaseVar.current === 0 && countVar.current === 0) {
      startRecording();
    }
    if (phaseVar.current + 1 < numPhases) {
      setPhase(phaseVar.current + 1);
      phaseVar.current = phaseVar.current + 1;
    } else {
      setPhase(0);
      setCount(countVar.current + 1);
      phaseVar.current = 0;
      countVar.current = countVar.current + 1;

      // determine if we need to move to the next exercise
      const countRef = countVar.current;
      if (workoutCount && workoutCount == countRef) {
        await handleOpenConfirmation();
      }           
    }
  };

  // advances exercise in the workout
  const advanceExercise = (vidId) => {
    setCount(0);
    countVar.current = 0;
    rest.updateIndex(vidId);
  };

  const detect = async (analyzer) => {
    if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null && webcamRef.current.video.readyState === 4) {
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;
      let feedback = null;
      let run = null;
      // get feedback from the analyzer
      try {
        run = await analyzer.run(video, phaseAngles[phaseVar.current].angles3D, phaseAngles[phaseVar.current].angles2D, exercise.focus[phaseVar.current], phaseVar.current, phaseAngles[phaseVar.current].orientation2D, phaseAngles[phaseVar.current].orientation3D);
        if (run != null) {
          isLoading && setIsLoading(false);
          feedback = run.feedback;
          let skeleton = run.skeleton;
          drawCanvas(skeleton.keypoints, feedback, run.flat, videoWidth, videoHeight, canvasRef);
          analyzer.updateHistory(run.angles);
          setControlScore(analyzer.controlScore);

          setInFrame(true);
        } else {
          setInFrame(false);
        }
      } catch (err) {
        console.log(err);
      }
      if (feedback == null) return;
      // update the phase, count, or exercise if necessary
      if (feedback.length === 0) {
        // proper form

        if (isStatic && staticFailed < 20) {
          // static exercise
          if (staticStarted > 20) {
            setCount(Math.round((Date.now() - startTime) / 1000));
            countVar.current = Math.round((Date.now() - startTime)/1000);
            staticFailed = 0;

            // start the recording if it hasn't started yet
            if (!capturing.current) {
              startRecording();
            };
          } else {
            staticStarted++;
            startTime = Date.now();
          }
        } else if (!isStatic) {
          // dynamic exercise

          if (durations[phaseVar.current] <= 0) {
            // the phase does not need to be held
            await advancePhase();
          }
          else if (durStart != null) {
            // the phase needs to be held, and the phase has already started
            currentDuration = Date.now() / 1000 - durStart;

            if (currentDuration >= durations[phaseVar.current]) {
              // the current phase has been held long enough
              currentDuration = -1;
              durStart = null;
              await advancePhase();
            }
          } else {
            // the phase needs to be held, and the phase has not started yet
            currentDuration = 0;
            durStart = Date.now() / 1000;
          }
        }
      } else if (isStatic && feedback.length > 0 && staticStarted <= 20) {
        staticStarted = 0;
      } else if (isStatic && staticStarted > 20 && feedback && feedback.length > 0) {
        if (staticFailed < 20) {
          staticFailed++;
        } else {
          handleOpenConfirmation();
        }
      } else if (!isStatic && durations[phaseVar.current] > 0) {
        // dynamic exercise, form break, and the phase has a duration

        if (staticFailed < 10) {
          // if the form has been broken for less than 10 frames, ignore it
          staticFailed++;
          currentDuration = Date.now() / 1000 - durStart;
        } else {
          staticFailed = 0;
          currentDuration = -1;
          durStart = null;
        }
      }  
    }
  };

  // handle reloading or direct access to the page by redirecting to the details page
  useEffect(() => {
    if (!rest.input) {
      const currentPath = location.pathname;
      const updatedUrl = currentPath.replace('/run', '');
      navigate(updatedUrl);
    }
  }, [rest.input, navigate]);

  // log the workout
  const handleLog = (videoId) => {
    dispatch(createLog({
      creator: user.result._id,
      reps: [countVar.current],
      exerciseNames: [title],
      exerciseIds: [exercise._id],
      videos: [videoId],
    }));

    dispatch(incrementStats(exercise.muscleGroups));
    navigate(`/logs`);
  };

  // log the set without uploading the video
  const handleLogWithoutUpload = async () => {
    handleLog(undefined);
  };

  // log the set and upload the video
  const handleLogWithUpload = async () => {
    const videoId = await handleUpload();
    handleLog(videoId);
  };

  // upload the video without logging the set and update the index
  const handleUploadWithoutLog = async () => {
    const videoId = await handleUpload();
    console.log(`Video upload successful: ${videoId}`);
    advanceExercise(videoId);
  };

  // discard the video without logging the set and update the index
  const handleDiscard = async () => {
    stopRecording();
    dispatch(incrementStats(exercise.muscleGroups));
    advanceExercise(undefined);
  };

  const drawCanvas = (pose, feedback, flat, videoWidth, videoHeight, canvas) => {
    const ctx = canvas.current.getContext("2d");
    canvas.current.width = videoWidth;
    canvas.current.height = videoHeight;

    drawText(ctx, 10, 20, `${countVar.current}`, 20);
    drawKeypoints(pose, 0.5, ctx);
    if (flat) {
      draw2DSkeleton(pose, .5, ctx);
    }
    else {
      draw3DSkeleton(pose, 0.5, ctx);
    }
    drawFeedback(pose, feedback, flat, 0.5, ctx);
  };

  const titleStyle = {
    color: theme.palette.secondary.main,
  };

  const alertStyle = {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid',
    borderColor: theme.palette.text.secondary,
    padding: '20px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    zIndex: 10,
    display: inFrame && !isLoading ? 'none' : 'block',
  };

  const alertTextStyle = {
    color: theme.palette.text.primary,
    weight: 'bold',
  };

  const logStyle = {
    marginTop: '20px',
  };

  // TODO: make the example image a demonstration of the exercise
  const exampleStyle = {
    backgroundImage: `url(${exercise?.exampleUrls ? exercise.exampleUrls[phaseVar.current] : ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100px',
    height: '30vh',
    width: '80%',
    mt: '10px',
    mb: '10px'
  };
  const imageContainer =  {
    position: 'relative',
    width: '100%',
    paddingBottom: '75%', // 75% of the width
    overflow: 'hidden',
  };

  if (!exercise) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Paper elevation={6} sx={alertStyle}>
        <Typography variant='h4' sx={alertTextStyle}>{!inFrame ? "Please make sure you are in frame!" : isLoading ? "Loading . . ." : "An error has occurred."}</Typography>
      </Paper>
      
      <Grid container direction="row" textAlign='center' justifyContent="space-between" alignItems='center' >
        <Grid item xs={4} sm={4}>
          {workoutTitle !== "" ?
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
              <Typography variant='p' sx={titleStyle}>{workoutTitle}</Typography>
              <ChevronRightIcon sx={titleStyle}/>
              <Typography variant='p' sx={titleStyle}>{title}</Typography>
            </Box>
            : <Typography variant='h6' sx={titleStyle}>{title}</Typography>}
          <Typography variant="p" color={theme.palette.text.secondary}>
            Created by:
            <Link to={`/creators/${exercise.creator._id}`} style={{ textDecoration: 'none', color: `${theme.palette.text.secondary}` }}>
              {` ${exercise.creator.username}`}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} >
          <Typography color="primary" variant='h4' id="phase">{phaseNames[phase]}</Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography color="primary" variant='h4' id="reps">{(isStatic ? "Timer: " + count : "Reps: " + count) + (workoutTitle !== '' ? " / " + workoutCount : "")}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ m: 2 }} />

      <Grid container direction='row' textAlign='center' justifyContent="space-between" alignItems='left' >
        <Grid item xs={12} sm={12} md = {8} lg={7}  style={imageContainer}>
          <Paper style={{ margin: '0 auto', position: 'relative' }}>
            <Webcam id="target" ref={webcamRef} style={{
              width: '100%',
              height: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
            }}/>
            <canvas ref={canvasRef} style={{
              position: 'absolute',
              width: '100%',
              height: 'auto',
              top: 0,
              left: 0,
              zIndex: 9,
              backgroundColor: 'transparent',
            }}/>  
            <canvas ref={offscreenCanvasRef} style={{ display: 'none' }} />   
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md = {4} lg={5}>
          <Paper elevation={0} sx={{ marginLeft: '10px' }}>
            <Typography variant='h5'>Example</Typography>
            <Container sx={exampleStyle}>

            </Container>

            <Divider sx={{ m: 2 }} />
  
            <Typography variant='h5'>Metrics</Typography>
            <Metrics controlScore={controlScore}/>
            {(!workoutCount && !isStatic) &&
              <Button onClick={handleOpenConfirmation} variant="contained" sx={logStyle} disabled={user==null || count < 1} size="large">Log your workout!</Button>
            }

            <Dialog
                  open={openConfirmation}
                  TransitionComponent={Transition}
                  keepMounted={false}
                  onClose={handleCloseConfirmation}
                  aria-describedby="alert-dialog-slide-description"
                  PaperProps={{
                    style: { zIndex: 11, overflowY: 'visible' }, // Ensure the dialog is in front
                  }}
                >
                  <DialogTitle>{"Upload Video"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Would you like to discard or upload the video of your set?
                    </DialogContentText>
                    <TextField
                      margin="dense"
                      id="title"
                      label="Title"
                      type="text"
                      fullWidth
                      size="small"
                      onChange={(e) => setVidTitle(e.target.value)}
                    />
                    <TextField
                      margin="dense"
                      id="description"
                      label="Description"
                      type="text"
                      fullWidth
                      size="small"
                      onChange={(e) => setVidDesc(e.target.value)}
                    />
                    <Box sx={{ maxWidth: 180, mt: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="visibility-select-label">Visibility</InputLabel>
                        <Select
                          labelId="visibility-select-label"
                          id="visibility-select"
                          value={vidVisibility}
                          label="Visibility"
                          size="small"
                          onChange={(e) => setVidVisibility(e.target.value)}
                        >
                          <MenuItem value='everyone'>Everyone</MenuItem>
                          <MenuItem value='followers'>Followers</MenuItem>
                          <MenuItem value='private'>Private</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <ThumbnailSelector videoUrl={videoURL} onUpdate={(data) => {setVidThumbUrl(data)}} />
                  </DialogContent>
                  <DialogActions>
                    <Button sx={{color: 'text.primary'}} onClick={workoutCount ? handleDiscard : handleLogWithoutUpload}>Discard</Button>
                    <Button disabled={!vidTitle} sx={{color: 'text.primary'}} onClick={workoutCount ? handleUploadWithoutLog : handleLogWithUpload}>Upload</Button>
                  </DialogActions>
                </Dialog>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Runnerhelper;