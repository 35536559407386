import React, { useEffect, useState } from 'react';
import { Radio, Divider, Autocomplete, FormLabel, IconButton, Collapse, Alert, FormControlLabel, Container, Checkbox, RadioGroup, TextField, Button, Typography, Paper, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from '../Form/FileInput';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { createExercise, updateExercise, getExerciseById, clearExercise } from '../../actions/exercise';
import ClearIcon from '@mui/icons-material/Clear';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { Analyzer } from '../Analyzer/analyze';
import * as tf from '@tensorflow/tfjs';
import MuscleSelector from "react-body-highlighter";

const Form = ({ currentId, setCurrentId }) => {
  // define form states
  const [loading, setLoading] = useState(false);
  const [exerciseData, setExerciseData] = useState({
    title: '',
    description: '',
    focus: [],
    thumbnail: null,
    phaseNames: [],
    exampleImages: [],
    angles: [],
    durations: [],
    visibility: 'followers'
  });

  // define muscle selector data and handler
  const [muscleSelectorData, setMuscleSelectorData] = useState([
    {
      name: "blank",
      muscles: []
    }
  ]);
  const handleClick = (muscleData) => {
    const { muscle } = muscleData;
    const muscles = muscleSelectorData[0].muscles;
    const newMuscles = muscles.includes(muscle) ? muscles.filter((m) => m !== muscle) : [...muscles, muscle];
    setMuscleSelectorData([
      {
        name: "blank",
        muscles: newMuscles
      }
    ]);
  };

  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const navigate = useNavigate();
  const theme = useTheme();
  tf.ready();

  // get exercise data if editing
  const exercise = useSelector((state) => state.exercise.exercise);
  useEffect(() => {
    if (currentId !== 0) {
      dispatch(getExerciseById(currentId));
    } else {
      dispatch(clearExercise());
    }

    return () => {
      dispatch(clearExercise());
    };
  }, [currentId, dispatch]);

  // define editing state
  const [editedKeys, setEditedKeys] = useState([]);

  // define phase states
  const [phaseNames, setPhaseNames] = useState(exerciseData.phaseNames);
  const [phaseImgs, setPhaseImgs] = useState([]);
  const [exampleImages, setExampleImages] = useState([]);
  const [durations, setDurations] = useState([]);
  const [focus, setFocuses] = useState(exerciseData.focus.map((i) => i?.map((j) => j > 0)));
  const [focusVals, setFocusVals] = useState(exerciseData.focus);
  const [keys, setKeys] = useState([]);

  // define thumbnail state for the raw image file
  const [rawThumbnail, setRawThumbnail] = useState(null);

  // define form error message state
  const [formError, setFormError] = useState('');

  // constant storing options for the leniency dropdown
  const leniencyOptions = {
    "Low": 7.5,
    "Medium": 12.5,
    "High": 20
  }

  function invert(obj) { 
    var invObj = {}; 
    for (var key in obj) invObj[obj[key]] = key; 
    return invObj; 
  }

  const leniencyValues = invert(leniencyOptions);

  // constant storing the value corresponding to default leniency
  const DEFAULT = leniencyOptions["Medium"];
  
  // ensure unique identifiers for each phase
  const generateKey = () => {
    if (keys.length === 0) {
      setKeys([0]);
      return 0;
    } else {
      const newKey = keys[keys.length - 1] + 1;
      setKeys([...keys, newKey]);
      return newKey;
    }
  }

  // create a key for each focus (upper, lower, etc.)
  const generateFocusKey = (phaseKey, focusStr) => {
    return phaseKey.toString() + focusStr;
  }

  const removePhase = (index) => {
    // copy states
    let newPhaseNames = [...phaseNames];
    let newPhaseImgs = [...phaseImgs];
    let newExampleImages = [...exampleImages];
    let newDurations = [...durations];
    let newKeys = [...keys];
    let newFocus = [...focus];
    let newFocusVals = [...focusVals];

    // remove phase
    newPhaseNames.splice(index, 1);
    newPhaseImgs.splice(index, 1);
    newExampleImages.splice(index, 1);
    newDurations.splice(index, 1);
    newKeys.splice(index, 1);
    newFocus.splice(index, 1);
    newFocusVals.splice(index, 1);

    // update states
    setPhaseNames(newPhaseNames);
    setPhaseImgs(newPhaseImgs);
    setExampleImages(newExampleImages);
    setDurations(newDurations);
    setKeys(newKeys);
    setFocuses(newFocus);
    setFocusVals(newFocusVals);
  }

  const addPhase = () => {
    // copy states and add new empty phase
    const newPhaseNames = [...phaseNames, ''];
    const newPhaseImgs = [...phaseImgs, ''];
    const newExampleImages = [...exampleImages, ''];
    const newDurations = [...durations, 0];
    const newFocus = [...focus, [false, false, false]];
    const newFocusVals = [...focusVals, [DEFAULT, DEFAULT, DEFAULT]];

    // update states
    setPhaseNames(newPhaseNames);
    setPhaseImgs(newPhaseImgs);
    setExampleImages(newExampleImages);
    setDurations(newDurations);
    setFocuses(newFocus);
    setFocusVals(newFocusVals);

    // generate unique key for new phase
    generateKey();
  }

  const setPhaseName = (name, index) => {
    // copy state
    let newPhaseNames = [...phaseNames];

    // update phase name
    newPhaseNames[index] = name;

    // update state
    setPhaseNames(newPhaseNames);
  }

  const setPhaseImg = (img, index) => {
    // copy state
    let newPhaseImgs = [...phaseImgs];

    // update phase image
    newPhaseImgs[index] = img;

    // update state
    setPhaseImgs(newPhaseImgs);

    // add the new key to the edited keys list
    setEditedKeys([...editedKeys, keys[index]]);
  }

  const setExampleImage = (img, index) => {
    // copy state
    let newExampleImages = [...exampleImages];

    // update example image
    newExampleImages[index] = img;

    // update state
    setExampleImages(newExampleImages);
  }

  const setDuration = (duration, index) => {
    // copy state
    let newDurations = [...durations];

    // update phase duration
    newDurations[index] = duration;

    // update state
    setDurations(newDurations);
  }

  const setFocus = (val, index) => {
    // copy state
    let newFocus = [...focus];

    // update focusVal if needed
    if (newFocus[index][val]) {
      let newFocusVals = [...focusVals];
      newFocusVals[index][val] = DEFAULT;
      setFocusVals(newFocusVals);
    }

    // update focus
    newFocus[index][val] = !newFocus[index][val];

    // update state
    setFocuses(newFocus);
  }

  const setFocusValues = (val, index, sig) => {
    //copy state
    let newFocusVals = [...focusVals];
    
    //update focus
    newFocusVals[index][val] = sig;

    //update state
    setFocusVals(newFocusVals);
  }

  // generalized callback function for setting phase properties
  const setPhase = (value, index, command) => {
    switch (command) {
      case 'name':
        setPhaseName(value, index);
        break;
      case 'image':
        setPhaseImg(value, index);
        break;
      case 'example':
        setExampleImage(value, index);
        break;
      case 'duration':
        setDuration(value, index);
        break;
      default:
        break;
    }
  };

  // getter for phase properties
  const getPhase = (index, command) => {
    switch (command) {
      case 'name':
        return phaseNames[index];
      case 'image':
        return phaseImgs[index];
      case 'example':
        return exampleImages[index];
      case 'duration':
        return durations[index];
      case 'focus':
        return focus[index];
      default:
        break;
    }
  };

  async function urlToFile(imageUrl, fileName) {
    const response = await fetch(imageUrl); // Fetch the image data from the URL
    const blob = await response.blob(); // Convert the response to a blob
    const file = new File([blob], fileName, { type: blob.type }); // Create a File object from the blob
    return file;
  }  

  // analyze a phase image
  async function analyzePhase(index, analyzer) {
    let image = document.createElement('img');
    image.src = phaseImgs[index]; 
    const analysis = await analyzer.create(image);
    return analysis;
  }

  // analyze all phase images
  const analyzeAll = async () => {
    const analyzer = new Analyzer();
    let phaseAngles = [];
    // analyze each of the newly entered images
    for (let i = 0; i < phaseImgs.length; i++) {
      if (editedKeys.includes(keys[i])) {
        const analysis = await analyzePhase(i, analyzer);
        phaseAngles.push(analysis);
      } else {
        phaseAngles.push(exerciseData.angles[i]);
      }
    }

    return { ...exerciseData, phaseNames: phaseNames, angles: phaseAngles, focus: focusVals, durations: durations };
  }

  const clear = async () => {
    setExerciseData({ title: '', description: '', focus: [], thumbnail: null, phaseNames: [], exampleImages: [], angles: [], durations: [], visibility: 'followers'});
    setPhaseNames([]);
    setPhaseImgs([]);
    setExampleImages([]);
    setDurations([]);
    setKeys([]);
    setFocuses([]);
    setFocusVals([]);
    setCurrentId(0);
    await dispatch(clearExercise());
  }

  // allow user to edit exercise data
  useEffect(() => {
    if (exercise && currentId !== 0) {
      console.log(exercise);
      setExerciseData(exercise);
      exercise.muscleGroups != null ? setMuscleSelectorData([{name: "blank", muscles: exercise.muscleGroups}]) : setMuscleSelectorData([{name: "blank", muscles: []}]);
      exercise.phaseNames != null ? setPhaseNames(exercise.phaseNames) : setPhaseNames([]);
      exercise.exampleUrls != null ? setPhaseImgs(exercise.exampleUrls) : setPhaseImgs([]);
      exercise.durations != null ? setDurations(exercise.durations) : setDurations([]);

      const fetchExampleImages = async () => {
        try {
          // Asynchronously get example images
          const getExampleImageFiles = async () => {
            let exampleFiles = [];
            for (let i = 0; i < exercise.exampleUrls.length; i++) {
              const file = await urlToFile(exercise.exampleUrls[i], "example" + i);
              exampleFiles.push(file);
            }
            return exampleFiles;
          };
    
          // Await the result from getExampleImageFiles
          const imageFiles = await getExampleImageFiles();
    
          // Set the exampleImages state with the actual image files
          setExampleImages(imageFiles);
          
        } catch (error) {
          console.log('error fetching phase image files:', error);
        }
      };

      fetchExampleImages();
      
      if (exercise.focus != null) {
        setFocuses(exercise.focus.map((i) => i?.map((j) => j > 0)));
        setFocusVals(exercise.focus);
      } else {
        setFocus([]);
      }
      setKeys([...Array(exercise.phaseNames.length).keys()]);
    }
  }, [exercise, currentId]);

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    for (let i in phaseImgs) {
      if (phaseImgs[i] === "") {
        setFormError("Please upload an image for each phase");
        return;
      }
    }

    setLoading(true);

    // analyze images
    const data = await analyzeAll();
    // check for empty phase names
    let emptyPhase = false;
    for (let i in data.phaseNames) {
        if (data.phaseNames[i] === "") {
          emptyPhase = true;
        }
    }

    // replace unchecked foci with -1
    let foci = JSON.parse(JSON.stringify(focusVals));
    for (let i = 0; i < foci.length; i++) {
      for (let j = 0; j < foci[i].length; j++) {
        if (!focus[i][j]) {
          foci[i][j] = -1;
        }
      }
    }

    // check for an empty focus
    const f = foci.map((phase) => phase.every((focus) => focus === -1));
    const emptyFocus = f.includes(true);

    if (data.title === "" || data.description === "") {
      setLoading(false);
      setFormError("Please fill out all fields");
    } else if (emptyPhase || data.phaseNames.length < phaseNames.length) {
      setLoading(false);
      setFormError("Please fill out all phase names");
    } else if (emptyFocus) {
      setLoading(false);
      setFormError("Please check at least one focus for each phase");
    } else {
      e.preventDefault();
      setFormError("");

      if (currentId === 0) {
        const result = await dispatch(createExercise({ ...data, 
          muscleGroups: muscleSelectorData[0].muscles, 
          creator: user?.result?._id, 
          focus: foci, 
          thumbnail: rawThumbnail,
          exampleImages: exampleImages
        }));
        if (result) {
          await clear();
          navigate('/exercises/' + result._id);
        }
      } else {
        if (rawThumbnail != null) {
          const result = await dispatch(updateExercise(currentId, { ...data, 
            muscleGroups: muscleSelectorData[0].muscles, 
            focus: foci, 
            thumbnail: rawThumbnail,
            exampleImages: exampleImages
          }));
          if (result) {
            await clear();
            navigate('/exercises/' + currentId);
          }
        } else {
          const result = await dispatch(updateExercise(currentId, { ...data, 
            muscleGroups: muscleSelectorData[0].muscles, 
            focus: foci,
            exampleImages: exampleImages
          }));
          if (result) {
            await clear();
            navigate('/exercises/' + currentId);
          }
        }
      }
    }
    setLoading(false);
  }

  const handleVisibilityChange = (event) => {
    setExerciseData({...exerciseData, visibility: event.target.value});
  }

  // Save both the base64 and file for the phase image
  const handlePhaseImageChange = (index, base64, file) => {
    setPhase(base64, index, 'image');
    setPhase(file, index, 'example');
  };

  const multichoiceStyle = {
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingDown: '30px',
  };
  const phaseStyle = {
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    backgroundColor: theme.palette.background.main,
    border: '1px solid',
    margin: '5px',
    padding: theme.spacing(2),
    maxWidth: '300px',
    minWidth: '300px'
  };
  const paperStyle = {
    padding: theme.spacing(2),
    borderRadius: 15,
    backgroundColor: theme.palette.background.paper,
  };
  const formStyle = {
    margin: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };
  const autocompleteStyle = {
    minWidth: '140px',
    maxWidth: '140px',
    display: 'flex'
  };
  const fieldStyle = {
    margin: '7px',
    paddingRight: '50px',
    width: '300px',
  };
  const durationStyle = {
    margin: '16px 7px 7px',
    paddingRight: '50px',
    width: '95%'
  };

  // if user is not signed in, display message
  if (!user?.result?.name) {
    return (
      <Paper style={paperStyle} elevation={6}>
        <Typography variant="h6" align="center">
          Please Sign In
        </Typography>
      </Paper>
    );
  }

  else if (user?.result?._id !== exercise?.creator?._id && currentId !== 0) {
    return (
      <Paper elevation={6}>
        <Typography variant="h6" align="center">
          Not Authorized to Edit this Exercise
        </Typography>
      </Paper>
    );
  }

  console.log('phaseImgs:', phaseImgs);

  return (
    <Container sx={{ mt: theme.spacing(1) }}>
      <Paper style={paperStyle} elevation={6}>
        <form autoComplete="off" noValidate style={formStyle} onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center" flexDirection="column" width="100%">
            <Typography color='primary' variant="h6">{currentId ? `Editing "${exercise?.title}"` : 'Exercise Builder'}</Typography>
            <TextField sx={{ m: 1 }} name="title" variant="outlined" label="Title" fullWidth value={exerciseData.title} onChange={(e) => setExerciseData({ ...exerciseData, title: e.target.value })}/>
            <TextField sx={{ m: 1 }} name="description" variant="outlined" label="Description" fullWidth multiline rows={4} value={exerciseData.description} onChange={(e) => setExerciseData({ ...exerciseData, description: e.target.value })} />

            <Typography color='text.secondary' variant="h6">Targeted Muscles</Typography>
            <Box margin={theme.spacing(1)} display='flex' justifyContent="space-between" flexDirection='row'>
              <MuscleSelector data={muscleSelectorData} onClick={handleClick} />
              <MuscleSelector
                type="posterior"
                data={muscleSelectorData}
                highlightedColors={["#e65a5a", "#db2f2f"]}
                onClick={handleClick}
              />
              <Box margin={theme.spacing(1)} display='flex' flexDirection='column'>
                {muscleSelectorData[0].muscles.map((muscle, index) => {
                  return <Typography key={index} color='text.secondary' variant="h6">{muscle}</Typography>;
                })}
              </Box>
            </Box>
      
            <Box margin={theme.spacing(1)} display='flex' justifyContent="space-between" flexDirection='row'>
              <Button sx={{m: '20px'}} variant="contained" color="primary" onClick={addPhase}> Add Phase </Button>
            </Box>

            <Box margin={theme.spacing(1)} display="flex" flexDirection="column" flexWrap="wrap" justifyContent="center" alignItems="center">
              {[...keys.keys()].map((index) => {
                return (
                  <Box key={keys[index]} style={phaseStyle}>
                    <TextField value={getPhase(index, 'name')} variant="outlined" label={"Phase Name"} fullWidth onChange={(e) => setPhase(e.target.value, index, 'name')} /> 
                      
                    <FileInput base64={true} val={phaseImgs[index]} onDone={({ base64, file }) => handlePhaseImageChange(index, base64, file)}>
                      Upload Image
                    </FileInput>

                    <Box color="secondary" style={multichoiceStyle}>
                      <FormLabel>Focus</FormLabel>
                      <RadioGroup>

                        {["Upper", "Lower", "Back"].map((pos, num) => {
                          return (
                            <Box display="flex" flexDirection="row" key={generateFocusKey(keys[index], pos)} sx={{ justifyContent: 'space-between'}}>
                              <FormControlLabel checked={focus[index][num]} onChange={() => {setFocus(num, index)}} control={<Checkbox />} label={pos} />
                              
                              <Autocomplete
                                style={!focus[index][num] ? {display: "none"} : autocompleteStyle}
                                disablePortal
                                id="autocomplete"
                                options={["Low", "Medium", "High"]}
                                value={leniencyValues[focusVals[index][num]]}
                                size="small"
                                sx={fieldStyle}
                                renderInput={(params) => <TextField {...params} label="Leniency" />}
                                isOptionEqualToValue={(option, value) => option?.exerciseId === value?.exerciseId}
                                onChange={(event, newValue) => {
                                    if (!newValue) return;
                                    setFocusValues(num, index, leniencyOptions[newValue]);
                                }}
                              />
                            </Box>
                          );
                        })}
                      </RadioGroup>
                    </Box>

                    <TextField value={getPhase(index, 'duration')} 
                      variant="outlined" 
                      type="number" 
                      label={"Phase Duration (seconds)"} 
                      size="small"
                      sx={durationStyle}
                      helperText="Leave 0 for static positions."
                      fullWidth 
                      onChange={(e) => setPhase(e.target.value, index, 'duration')} 
                    />

                    <IconButton aria-label="delete" color="secondary" onClick={() => removePhase(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>

            <Divider sx={{m: '15px 0px', width: '100%'}}/>
            <Box color="secondary" display="flex" flexDirection="column" alignItems="center">
              <FormLabel>Visibility</FormLabel>
              <RadioGroup row onChange={handleVisibilityChange} value={exerciseData.visibility}>
                {["Everyone", "Followers", "Private"].map((option, index) => (
                  <Box key={index} display="flex" flexDirection="row" sx={{ justifyContent: 'space-between' }}>
                    <FormControlLabel value={option.toLowerCase()} control={<Radio />} label={option} />
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            <Divider sx={{m: '15px 0px'}}/>
            
            <FileInput val={currentId === 0 ? '' : (exercise?.thumbnailUrl || '')} base64={false} onDone={( file ) => setRawThumbnail(file)} >
              Upload Thumbnail
            </FileInput>

            <Box sx={{ width: '100%', mt: '10px' }}>
              <Collapse in={formError !== ""}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setFormError("");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {formError}
                </Alert>
              </Collapse>
            </Box>

            <div>
              <LoadingButton sx={{m: '10px'}} loading={loading} startIcon={<PublishIcon/>} variant="contained" onClick={handleSubmit} color="primary">
                <span>
                  Submit
                </span>
              </LoadingButton> 
              <Button sx={{m: '10px'}} startIcon={<ClearIcon/>} variant="contained" color="secondary" onClick={clear}>Clear</Button>
            </div>
            
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

export default Form;
